/*istanbul ignore next*/
export const environment = {
  production: false,
  envName: "dev",
  mopar: false,
  cx: false,
  resource: "https%3A%2F%2Fdai-dev.extra.fcagroup.com%2Fadfs",
  apiUrl: "https://azapp-11798-p-002-dev.azurewebsites.net/",
  // apiUrl: "https://azapp-11798-p-002-stage.azurewebsites.net/",
  auth: {
    clientId: "a8ade7f1-bfd8-468c-b683-813c91df4915",
    authority: "https://sts-dev.fiat.com/adfs",
    knownAuthorities: ["sts-dev.fiat.com"],
    validateAuthority: false,
    redirectUri: "https://dai-dev.extra.fcagroup.com/",
    cacheLocation: "localStorage",
    postLogoutRedirectUri: "https://dai-dev.extra.fcagroup.com/",
    navigateToLoginRequestUrl: false,
    // consentScopes: ['https://dai-dev.extra.fcagroup.com/adfs'],
    // scopes: ['https://dai-dev.extra.fcagroup.com/adfs']
  },
};
